import { Outlet } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Button } from '@mui/material';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Stack, OutlinedInput, FormHelperText } from '@mui/material';
import Logo from 'assets/images/zaao-logo2.png';
import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// project imports
import Customization from '../Customization';
import BackgroundPattern2 from 'ui-component/cards/BackgroundPattern2';
import imgMain from 'assets/images/zaao_portals.jpg';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayoutWithoutLogin = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center">
            <Grid item md={12} lg={12} xs={12} sx={{ minHeight: '100vh' }}>
                <Grid
                    sx={{ minHeight: '100vh' }}
                    container
                    alignItems={matchDownSM ? 'center' : 'flex-start'}
                    justifyContent={matchDownSM ? 'center' : 'space-between'}
                >
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} p={1} sx={{ textAlign: 'center' }}>
                            <Box alignItems="center" pl={2}>
                                <ButtonBase disableRipple>
                                    <a href="https://zaao.lv">
                                        <img src={Logo} alt="ZAAO" width={50} />
                                    </a>
                                </ButtonBase>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={3} sx={{ mt: 0, background: 'rgb(209, 204, 214)' }}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MinimalLayoutWithoutLogin;
